<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование инструкции"
        update
        url-update="/interactives-instruction/update/:id"
        url-back="/interactives-instruction"
        action-one="interactives_instruction/one"
        :fields="fields"
    >
        <interactives-instruction-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import InteractivesInstructionForm from '@/components/forms/InteractivesInstructionForm.vue'
export default {
    name: 'InteractivesInstructionUpdate',
    components: { FormView, InteractivesInstructionForm },
    computed: {
        fields () {
            return ['name', 'content']
        }
    }
}
</script>